<template>
  <v-card>
    <v-card-subtitle>Productos Vendidos</v-card-subtitle>
    <br>
    <v-card-text class="d-flex flex-column ">
      <v-row>
        <v-col sm="12" lg="12">
          <v-avatar size="10" class="mr-1">
            <v-icon large color="primary">
              {{ icons.mdiCheckboxBlankCircle }}
            </v-icon>
          </v-avatar>
          <span> Total Productos</span> <br>
          <strong class="ml-4  "> {{ datos.total_pedidos }}</strong>
        </v-col>
        <v-col sm="12" lg="12">
          <v-avatar size="10" class="mr-1">
            <v-icon large color="error">
              {{ icons.mdiCheckboxBlankCircle }}
            </v-icon>
          </v-avatar>
          <span> Productos Anulados</span> <br>
          <strong class="ml-4  "> {{ datos.pedidos_anulados }}</strong>
        </v-col>
        <v-col sm="12" lg="12">
          <v-avatar size="10" class="mr-1">
            <v-icon large color="success">
              {{ icons.mdiCheckboxBlankCircle }}
            </v-icon>
          </v-avatar>
          <span> Productos Pagados</span> <br>
          <strong class="ml-4  "> {{ datos.pedidos_pagados }}</strong>
        </v-col>
        <v-col sm="12" lg="12">
          <v-avatar size="10" class="mr-1">
            <v-icon large color="info">
              {{ icons.mdiCheckboxBlankCircle }}
            </v-icon>
          </v-avatar>
          <span> Productos Pendientes</span> <br>
          <strong class="ml-4  "> {{ datos.pedidos_pendientes }}</strong>
        </v-col>
      </v-row> 
    </v-card-text> 
     
  </v-card>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
  mdiCheckboxBlankCircle
} from '@mdi/js'
import MaestroService from '@/api/servicios/MaestroService'
import Vue from 'vue'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  props: {
    id_fecha: String,
    id_puesto: Number
  },
  setup(props) {

    let counterConsultas = ref(0)
    const datos = ref({})
    onBeforeMount(() => {
      setTimeout(() => {
        ConsultarDatos(true)
      }, 1000)
    })
    watch(props, () => {
      ConsultarDatos(false)
    })

    watch(counterConsultas, () => {

      setTimeout(() => {
        ConsultarDatos(true)
      }, 5000)
    })

   
    const ConsultarDatos = (actualizar = false) => {

      MaestroService.reporteCantidadComandasPuesto({ id_fecha: props.id_fecha, id_puesto :  props.id_puesto })
        .then(response => {
          console.log(response.data)
          datos.value = response.data.datos
        })
        .catch(error => {
          console.error(error)
          store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'ConsultarDatos',
                })
        })
        .finally(() => {
          if (actualizar == true)
            counterConsultas.value += 1
        })
    }


    return {
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiCheckboxBlankCircle
      },
      datos 
    }
  },
}
</script>

 

