<template>
  <v-row class="py-0 my-0">
    <v-col cols="12" class="py-0 my-0">
      <v-row class="match-height">
        <v-col cols="12" lg="3" md="6" sm="6">
          <statistics-card-vertical
            :change="''"
            color="primary"
            :icon="icons.mdiPoll"
            :statistics="`$${funciones.formatoNumeric(datos.total_venta_total)}`"
            :subtitle="datos.porcentaje < 100 ? `Real: $${funciones.formatoNumeric(datos.total_venta_real)}` : ''"
            stat-title="Venta"
          >
          </statistics-card-vertical>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6">
          <statistics-card-vertical
            :change="''"
            color="info"
            :icon="icons.mdiCurrencyUsd"
            :statistics="`$${funciones.formatoNumeric(datos.total_costo_total)}`"
            stat-title="Costo Venta"
          >
          </statistics-card-vertical>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6">
          <statistics-card-vertical
            :change="''"
            color="primary5"
            :icon="icons.mdiCurrencyUsd"
            :statistics="`$${funciones.formatoNumeric(
              (datos.porcentaje == 100 ? datos.total_venta_total : datos.total_venta_real) - datos.total_costo_total,
            )}`"
            stat-title="Ganancia Percibida"
          >
          </statistics-card-vertical>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6">
          <statistics-card-vertical
            :change="''"
            color="primary"
            :icon="icons.mdiPoll"
            :statistics="`$${funciones.formatoNumeric(datos.total_pago)}`"
            :subtitle=" `Pendientes: $${funciones.formatoNumeric(datos.total_pendiente)}` "
            stat-title="Comandas Pagadas"
          >
          </statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>
   
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import MaestroService from '@/api/servicios/MaestroService'
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
// demos

export default {
  props: {
    id_fecha: String,
    id_puesto: Number,
  },
  components: {
    StatisticsCardVertical,
  },
  setup(props) {
    const datos = ref({
      total_venta_total: 0,
      total_costo_total: 0,
      total_pago: 0,
      total_venta_real: 0,
      total_pendiente: 0,
      total_anulado: 0,
      total_item_anulado: 0,
      porcentaje: 0,
    })

    let counterConsultas = ref(0)
    watch(counterConsultas, () => {
      setTimeout(() => {
        ConsultarDatos(true)
      }, 5000)
    })

    onBeforeMount(() => {
      setTimeout(() => {
        ConsultarDatos(true)
      }, 1000)
    })

    watch(props, () => {
      ConsultarDatos(false)
    })

    const ConsultarDatos = actualizar => {
      MaestroService.reporteDatosVentasGeneralPuesto({ id_fecha: props.id_fecha, id_puesto: props.id_puesto })
        .then(response => {
          console.log(response.data)
          datos.value = response.data.datos
        })
        .catch(error => {
          console.log(error)
        })
        .finally(dato => {
          if (actualizar == true) counterConsultas.value += 1
        })
    }

    return {
      datos,
      icons: {
        mdiPoll,
        mdiLabelVariantOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
      },
      funciones,
    }
  },
}
</script>
    