<template>
  <div>
    <v-row class="match-height">
      <v-row class="py-0 my-0">
        <v-col cols="12" class="py-0 my-0">
         

          <DatePicker v-model="fechaSelectObj" :dense="true" :outlined="true" />
       
        </v-col>
      </v-row>
    </v-row>

    <indicadores-generales
      :id_puesto="store.state.puestoSelect.id_puesto"
      :id_fecha="fechaSelectObj.fecha"
    ></indicadores-generales>

    <v-row class="match-height">
      <v-col lg="8" md="12" sm="12" class="mt-4">
        <v-card>
          <v-card-subtitle> Ventas por locatario/area</v-card-subtitle>
          <TablaVentasPorPuesto
            :id_puesto="store.state.puestoSelect.id_puesto"
            :id_fecha="fechaSelectObj.fecha"
            :id_grupo="gruposSelect"
          ></TablaVentasPorPuesto>
        </v-card>
      </v-col>
      <v-col lg="4" md="12" sm="12" class="mt-4">
        <v-card>
          <IndicadoresContadorPedidos
            :id_puesto="store.state.puestoSelect.id_puesto"
            :id_fecha="fechaSelectObj.fecha"
          ></IndicadoresContadorPedidos>
        </v-card>
      </v-col>
      <v-col lg="12" md="12" sm="12" class="mt-4" >
        <v-card>
          <v-card-subtitle> Total locatario/area por forma pago</v-card-subtitle>
          <TablaPuestosFormasPago 
          :id_fecha="fechaSelectObj.fecha"  
          :id_puesto = "store.state.puestoSelect.id_puesto" 
          :ver="['FORMA_PAGO']"
          ></TablaPuestosFormasPago>
        </v-card>
      </v-col>
      <v-col lg="5" md="12" sm="12" class="mt-4">
        <v-card>
          <v-card-subtitle> Movimientos de inventario</v-card-subtitle>
          <TablaMovimientoProductoPorPuesto
            :id_puesto="store.state.puestoSelect.id_puesto"
            :id_fecha="fechaSelectObj.fecha" 
            
          ></TablaMovimientoProductoPorPuesto>
        </v-card>
      </v-col>
      <v-col lg="7" md="12" sm="12" class="mt-4">
        <v-card>
          <v-card-subtitle> Productos Anulados</v-card-subtitle>
          <TablaItemsAnulados
            :id_fecha="fechaSelectObj.fecha"
            :id_puesto="store.state.puestoSelect.id_puesto"
          ></TablaItemsAnulados>
        </v-card>
      </v-col>
     
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import router from '@/router'
import IndicadoresContadorPedidos from './componentes/IndicadoresContadorPedidos.vue'
import IndicadoresGenerales from './componentes/IndicadoresGenerales.vue'
import TablaVentasPorPuesto from '../../administracion/dashboard/componentes/TablaVentasPorPuesto.vue'
import TablaItemsAnulados from '../../administracion/dashboard/componentes/TablaItemsAnulados.vue'
import DatePicker from '@/components/DatePickerVentas.vue'
import TablaMovimientoProductoPorPuesto from './componentes/TablaMovimientoProductoPorPuesto.vue'

import TablaPuestosFormasPago from '../../administracion/dashboard/componentes/TablaPuestosFormasPago.vue'
// demos

export default {
  components: {
    StatisticsCardVertical,
    IndicadoresGenerales,
    TablaVentasPorPuesto,
    TablaItemsAnulados,
    IndicadoresContadorPedidos,
    DatePicker,
    TablaMovimientoProductoPorPuesto,
    TablaPuestosFormasPago
  },
  setup() {
    const fechas = ref([])
    const fechaSelect = ref(999999)
    const fechaSelectObj = ref({
      fecha: '2000-01-01',
      grupos: [],
    })
    onBeforeMount(() => {
      CargarFechas()
    })
    const CargarFechas = () => {
      MaestroService.reporteDatosFechas()
        .then(response => {
          fechas.value = response.data.datos
        })
        .catch(error => {
          store.commit('setAlert', {
                message: error,
                type: 'error',
                error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'CargarFechas',
              })
        })
        .finally(item => {
          fechaSelect.value = fechas.value[0].id
        })
    }
    return {
      store,
      CargarFechas,
      fechas,
      fechaSelect,
      fechaSelectObj
    }
  },
}
</script>
